<template>
  <div class="md:p-4 max-w-xl">
    <div>
      <sqr-page-header title="Modèles" class="mb-8">
        <sqr-button
          icon="plus"
          label="Nouveau un modèle"
          color="primary"
          @click="add()"
          :loading="adding"
        />
      </sqr-page-header>
    </div>
    <sqr-error-banner :error="addError" />
    <sqr-error-banner :error="loadError" />

    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <ul>
        <li v-for="model in models" :key="model.id" class="border-t first:border-t-0">
          <router-link :to="{name: 'inquiry-model-questions', params: {bid, mid: model.id}}"
            class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
          >
            <div class="px-4 py-4 sm:px-6">
              <div class="flex items-center justify-between">
                <div
                  class="text-sm leading-5 font-medium text-blue-600 truncate"
                >
                  {{ model.name }}
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import broker from '../broker';
import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';

export default {
  name: 'InquiryModels',
  mixins: [broker],
  components: { SqrPageHeader, SqrButton, SqrErrorBanner },
  computed: {
    ...mapState('inquiryModels', { models: 'docs', loadError: 'error' }),
    ...mapState('inquiryModels', ['loading'])
  },
  data() {
    return {
      adding: false,
      addError: null
    };
  },
  mounted() {
    const bid = this.bid;
    this.sub({
      path: `brokers/${bid}/offer-models`,
      sortBy: [['name', 'asc']]
    });
  },
  methods: {
    ...mapActions('inquiryModels', ['sub', 'unsub']),
    add() {
      const name = prompt('Nom du nouveau modèle');
      if (!name) return;
      const bid = this.bid;
      try {
        this.addError = null;
        this.adding = true;
        this.$db()
          .collection(`brokers/${bid}/offer-models`)
          .add({
            name,
            created: new Date().toISOString(),
            questions: [],
            contacts: []
          });
      } catch (error) {
        this.addError = error;
        return Promise.reject(error);
      } finally {
        this.adding = false;
      }
    }
  }
};
</script>
